
import React from "react";
import Contact from "../components/Contact";
import Layout from "../components/Layout";

export const ContactPageTemplate = () => (
  <div>
    <div className="container py-5">
      <Contact />
    </div>
  </div>
);

ContactPageTemplate.propTypes = {};

const ContactPage = () => {
  return (
    <Layout>
      <ContactPageTemplate />
    </Layout>
  );
};

export default ContactPage;
